import React from 'react';
import NavBar from '../components/Navbar/navbar';
import AboutSlider from '../components/AboutSlider/AboutSlider';
import HeroContainer from '../components/HeroContainer/HeroContainer';
import Services from '../components/Services/Services';
import TokenInfoSection from '../components/TokenInfo/TokenInfoSection';
import StepsToStartTrading from '../components/Steps/StepsToStartTrading';
import Footer from '../components/Footer/Footer';
import Meta from '../components/Meta';
import Head from 'next/head';
import CookieConsent from '../components/CookieConsent'; 

const IntroductionPage = () => {
  return (
    <>
      <Head>
        <title>Home | PINX Global Markets</title>
        <Meta title="PINX Global Markets" />
        <link rel="icon" href="/assets/PinxLogo.png" type="image/png" />
      </Head>
      <NavBar />
      <CookieConsent /> 
      <HeroContainer />
      <AboutSlider />
      <Services />
      <TokenInfoSection />
      <StepsToStartTrading />
      <Footer />
    </>
  );
};

export default IntroductionPage;
